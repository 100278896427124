
import React, { useState, useEffect, createRef } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as Scroll from 'react-scroll';

import Global from '../layout/global';
import { graphql } from 'gatsby';

import './docs.scss';

/** Helper function for rendering the table of contents */
const renderTOC = (list, currentSection, setCurrentSection) => {
    const Link = Scroll.Link;
	return <ul>
		{list.map((anchor, index) => (
			<li key={ index }
				className={`#${currentSection}` === anchor.url ? 'enable' : 'disable'}>
				<Link
					to={ anchor.url ? anchor.url.replace('#', '') : '' }
					hashSpy={ true }
					spy={ true }
					smooth={ true }
					offset={ -185 }
					onSetActive={setCurrentSection}
					duration={ 750 }>
						{ anchor.title }
				</Link>
				{anchor.items && anchor.items.length && renderTOC(anchor.items, setCurrentSection)}
			</li>
		))}
	</ul>;
}

/**
 * Custom template that renders docs
 */
const DocsTemplate = ({ path, data, pathContext }) => {
	const doc = createRef();
    const [ TOCExpanded, setTOCExpanded ] = useState(false);
	useEffect(() => {
		if (window.location.hash !== '') {
			const scroll = Scroll.animateScroll;
			const loc = window.location.hash.slice(1);
			const offset = document.getElementById(loc).getBoundingClientRect().top;
			scroll.scrollMore(offset - 150);
		}
		if (localStorage && localStorage.getItem('expandedState') === 'false') {
			setTOCExpanded(false);
		} else {
			setTOCExpanded(true);
		}
	}, []);
    const [ currentSection, setCurrentSection ] = useState(null);
	const post = data.mdx;
	const toc = data.mdx.tableOfContents.items;
	let img = '';
	if (post.frontmatter.featuredimage) {
		img = post.frontmatter.featuredimage.childImageSharp.sizes.src;
	} else {
		img = '';
	}
	const navigation = data.allMdx.edges.map(
		(item) => {
			return {
				path: item.node.fields.slug,
				title: item.node.frontmatter.nav_label,
				active: item.node.fields.slug === pathContext.slug
			}
		}
	)
	// Decide to expand or hide the docs
	let className = 'c-doc-toc';
	let allowToggle = true;
	if (! toc || toc.length === 0) {
		className += ' c-doc-full';
		allowToggle = false;
	} else if (toc && toc.length !== 0 && TOCExpanded !== true) {
		className += ' c-doc-full';
	}
	return (
		<Global
			page="docs"
			title={ `${post.frontmatter.title}` }
			image={ img }
			url={ path }
			background="/img/background/homepage2.jpg"
            keywords={ post.frontmatter.keywords !== null && post.frontmatter.keywords }
			description={ post.frontmatter.description }>
			<div className={ className }>
				<div className="c-doc-upper">
					<div
						className={ `c-doc-upper-left glitch` }
						data-text="CopperheadOS">
						CopperheadOS
					</div>
					<div className="c-doc-upper-right">
						<a target="_blank" rel="noreferrer" href="https://github.com/CopperheadOS/copperheados.github.io" className="source">
							<img src={`/img/icons/source.svg`} alt="Source code" />
							View Source
						</a>
					</div>
				</div>
				<div className="c-doc-inner">
					<nav className="c-doc-nav">
						<div className="c-doc-nav-inner">
							<span>Documentation</span>
							{navigation.map((item, index) =>
								<a key={index} className={item.active ? 'active' : ''} href={`/android${item.path}`}>
									{item.title}
								</a>
							)}
						</div>
					</nav>
					{toc && toc.length !== 0 && <div className={`c-doc-nav ${TOCExpanded === true ? 'show' : 'hide'}`}>
						<div className={ `c-doc-nav-inner c-doc-toc` }>
							{TOCExpanded}
							<span>
								Table of Contents
								<button
									title="Toggle the Table of Contents"
									className="c-doc-toggle"
									onKeyDown={() => {
										setTOCExpanded(! TOCExpanded);
										localStorage.setItem('expandedState', false);
									}}
									onClick={() => {
										setTOCExpanded(! TOCExpanded);
										localStorage.setItem('expandedState', false);
									}}>&laquo;</button>
							</span>
							{renderTOC(toc, currentSection, setCurrentSection)}
						</div>
					</div>}
					<div className="c-doc-body">
						<h1 className="c-doc-body-title">
							{!TOCExpanded && allowToggle === true &&
								<button
									title="Toggle the Table of Contents"
									className="c-doc-toggle left"
									onKeyDown={() => {
										setTOCExpanded(! TOCExpanded);
										localStorage.setItem('expandedState', true);
									}}
									onClick={() => {
										setTOCExpanded(! TOCExpanded);
										localStorage.setItem('expandedState', true);
									}}>&raquo;</button>
							}
							{ post.frontmatter.title }
						</h1>
						{post.frontmatter.featuredimage_onpage === true && img !== '' &&
						  <img className="c-doc-body-image" alt="Body img" src={ img } />}
						<div ref={doc} className="c-doc-body-inner">
							<MDXRenderer>{ post.body }</MDXRenderer>
						</div>
					</div>
				</div>
            </div>
		</Global>
	);
};

export const pageQuery = graphql`
	query DocsBySlug($slug: String!) {
		allMdx(
			filter: {
				frontmatter: { layout: { eq: "docs" } }
			}
			sort: { fields: [frontmatter___order], order: ASC }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						nav_label
					}
				}
			}
		}
		mdx(fields: { slug: { eq: $slug } }) {
			id
			excerpt(pruneLength: 160)
			body
			tableOfContents
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				description
				subtitle
				keywords
				author
				slug
				background
				featuredimage_onpage
				featuredimage {
					childImageSharp {
					    sizes(maxWidth: 1500) {
					 	    ...GatsbyImageSharpSizes
					    }
					}
				}
			}
		}
	}
`;

export default DocsTemplate;
